<template>
  <div>
    <b-card class="mb-0">
      <div v-if="!loader">
        <b-tabs content-class="pt-1" v-model="tabIndex">
          <b-tab
              v-for="(currency, index) in currencies"
              :key="currency.id"
              @click="changeTab(currency.id, currency.name, index)"
          >
              <template #title>
                  <span>{{ currency.name }}</span>
              </template>
          </b-tab>
        </b-tabs>
        <b-row v-if="this.$store.state.auth.user.crole != 'seller'" class=" ml-auto justify-center">
          <b-card
            class="col-xl-5 col-lg-6 col-md-6 col-xs-12 m-1"
            no-body
            style="border: 1px solid lightgray"
          >
            <b-card-body class="row justify-content-between align-items-start">
              <div class="truncate col-md-12" align="start">
                <h6>{{ $t("saldo_balance") }}</h6>
                <h2 class="mb-25 font-weight-bolder" style="color:#1B4332 ;">
                  <span v-if="data.openBalance">
                    {{ currency.name }} {{ data.openBalance }}</span>
                  <span v-else>{{ currency.name }} 00’000.00</span>
                </h2>
              </div>
              <br />
              <div
                class="truncate col-md-12 mt-1"  v-if="seller != 0" align="start">
                <h6>{{ $t("allocated_amount") }}</h6>
                <h2 class="mb-25 font-weight-bolder">
                  <span v-if="data.allocated">
                    {{ currency.name }} {{ data.allocated }}</span>
                  <span v-else>{{ currency.name }} 00’000.00</span>
                </h2>
              </div>
            </b-card-body>
          </b-card>
          <b-card
            no-body
            class="card-statistics col-xl-6 col-lg-6 col-md-6 col-xs-12 m-1"
            style="border: 1px solid lightgray"
          >
            <b-card-body class="statistics-body">
              <b-row>
                <b-col md="3" sm="6" class="mb-2 mb-md-0 col-md-6 mt-1 mb-1">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-primary">
                        <feather-icon size="24" icon="TrendingUpIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto" v-if="seller != 0">
                      <h4 class="font-weight-bolder mb-0">
                        <span v-if="data.invoicesPurchased">{{
                          data.invoicesPurchased
                        }}</span>
                        <span v-else>0</span>
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t("purchased_invoices_fractions") }}
                      </b-card-text>
                    </b-media-body>
                    <b-media-body class="my-auto" v-else>
                      <h4 class="font-weight-bolder mb-0">
                        <span v-if="data.invoicesPurchased">
                            {{ currency.name }} {{ data.invoicesPurchased }}</span>
                        <span v-else>{{ currency.name }} 00’000.00</span>
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t("invoices_sold") }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col
                  md="3"
                  sm="6"
                  class="mb-2 mb-md-0 col-md-6 mt-1 mb-1"
                  v-if="seller == 1"
                >
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-success">
                        <feather-icon size="24" icon="DollarSignIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        <span v-if="data.amountOutstanding && data.amountOutstanding != 0"> {{ currency.name }} {{ numberFormat(data.amountOutstanding) }} </span>
                        <span v-else>{{ currency.name }}  00’000.00</span>
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t("outstanding_amount") }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col md="3" sm="6" class="mb-2 mb-md-0 col-md-6 mt-1 mb-1">
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-success">
                        <feather-icon size="24" icon="DollarSignIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto" v-if="seller != 0">
                      <h4 class="font-weight-bolder mb-0">
                        <span v-if="data.amountPurchased">{{ currency.name }} {{ numberFormat(data.amountPurchased) }}</span>
                        <span v-else>{{ currency.name }} 00’000.00</span>
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t("purchased_volume") }}
                      </b-card-text>
                    </b-media-body>
                    <b-media-body class="my-auto" v-else>
                      <h4 class="font-weight-bolder mb-0">
                        <span v-if="data.amountPurchased">{{ currency.name }} {{ data.amountPurchased | formatAmount }}</span>
                        <span v-else>{{ currency.name }} 00’000.00</span>
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t("sold_volume") }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
                <b-col
                  md="3"
                  sm="6"
                  class="mb-2 mb-md-0 col-md-6 mt-1 mb-1"
                  v-if="seller == 1"
                >
                  <b-media no-body>
                    <b-media-aside class="mr-2">
                      <b-avatar size="48" variant="light-success">
                        <feather-icon size="24" icon="DollarSignIcon" />
                      </b-avatar>
                    </b-media-aside>
                    <b-media-body class="my-auto">
                      <h4 class="font-weight-bolder mb-0">
                        <span v-if="data.yieldSoFar && data.yieldSoFar != 0"> {{ currency.name }} {{ numberFormat(data.yieldSoFar) }}</span>
                        <span v-else>{{ currency.name }} 00’000.00</span>
                      </h4>
                      <b-card-text class="font-small-3 mb-0">
                        {{ $t("yield_so_far") }}
                      </b-card-text>
                    </b-media-body>
                  </b-media>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>
        </b-row>
        <b-row v-else-if="$store.state.auth.user.crole == 'seller'" class="col-12 ml-ato">
          <b-card
              no-body
              class="card-statistics col-xl-12 col-lg-12 col-md-12 col-xs-12"
              style="border: 1px solid lightgray"
            >
              <b-card-body class="statistics-body">
                <b-row>
                  <b-col md="4" sm="4" class="mb-2 mb-md-0 col-md-4 mt-1 mb-2">
                    <b-media no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar size="58" variant="light-success">
                          <feather-icon size="34" icon="DollarSignIcon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h3 class="font-weight-bolder mb-0">
                          <span v-if="balance && balance.openBalance">{{ currency.name }} {{ data.openBalance }}</span>
                          <span v-else>{{ currency.name }} 00’000.00</span>
                        </h3>
                        <b-card-text class="font-small-3 mb-0">
                          {{ $t("liquidity_received") }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                  <b-col  md="4"  sm="4" class="mb-2 mb-md-0 col-md-4 mt-1 mb-2"  >
                    <b-media no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar size="58" variant="light-primary">
                          <feather-icon size="34" icon="TrendingUpIcon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h3 class="font-weight-bolder mb-0">
                          <span v-if="balance && balance.invoicesPurchased">{{
                              balance.invoicesPurchased
                            }}</span>
                          <span v-else>00</span>
                        </h3>
                        <b-card-text class="font-small-3 mb-0">
                          {{ $t("invoices_sold") }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                  <b-col  md="4"  sm="4" class="mb-2 mb-md-0 col-md-4 mt-1 mb-2"  >
                    <b-media no-body>
                      <b-media-aside class="mr-2">
                        <b-avatar size="58" variant="light-success">
                          <feather-icon size="34" icon="DollarSignIcon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="my-auto">
                        <h3 class="font-weight-bolder mb-0">
                          <span v-if="balance && balance.amountOutstanding">{{ currency.name }} {{ balance.amountOutstanding}}</span>
                          <span v-else>{{ currency.name }} 00’000.00</span>
                        </h3>
                        <b-card-text class="font-small-3 mb-0">
                          {{ $t("amount_outstanding") }}
                        </b-card-text>
                      </b-media-body>
                    </b-media>
                  </b-col>
                </b-row>
              </b-card-body>
            </b-card>
        </b-row>
      </div>
      <div v-else>
        <img src="/new-loader.svg" />
      </div>
    </b-card>
    <b-card v-if="transactions.length > 0" class="mt-2 mb-0">
          <b-col md="12" class="mb-5" v-if="transactions.length > 0">
            <Table :url="url" :fields="fields" :payload="payload">
              <template #head()="scope">
                <div class="text-nowrap">{{ $t(scope.label) }}</div>
              </template>
              <template #cell(currency)="">
                <span>CHF</span>
              </template>
              <template #cell(id)="data">
                <span style="width:55px;" class="badge badge-light-secondary">T-{{data.value}}</span>
              </template>
              <template #cell(type_description)="data">
                <span v-if="$store.state.auth.user.crole == 'seller' && data.item.type_description == 'Purchase' ">
                  {{ $t("sold") }}</span>
                  <span v-else-if="data.item.type_description == 'invoice_sold' || data.item.type_description == 'invoice_paid'">  {{ $t("invoice_paid") }}</span>
                  <span v-else>  {{ $t("" + data.item.type_description) }}</span>
              </template>

              <template #cell(fraction)="data">
                <span v-if="data.item.fraction">
                  {{ data.item.fraction.fraction }}%
                </span>
                <span v-else>- </span>
              </template>
              <!--<template #cell(financing_status)="data">-->
                <!--<span v-if="data.item">-->
                  <!--{{ data.item.discount_percentage }} %-->
                <!--</span>-->
                <!--<span v-else>- </span>-->
              <!--</template>-->
              <template #cell(fraction_amount)="data">
                <span v-if="data.item.fraction">
                  {{ data.item.amount | formatAmountWithoutCurrency }}
                </span>
                <span v-else>{{
                  data.item.amount | formatAmountWithoutCurrency
                }}</span>
              </template>
              <template #cell(fraction_discount)="data">
                <span v-if="data.item.fraction">
                  {{ data.item.fraction.discount }}%
                </span>
                <span v-else>-</span>
              </template>
              <template #cell(fraction_hive_fee)="data">
                <span v-if="data.item.fraction">
                  {{ data.item.fraction.hive_fee | formatAmount }}
                </span>
                <span v-else>-</span>
              </template>
              <template #cell(invoice_id)="data">
                <span v-if="data.item.invoice_id != null && data.item.invoice_id != 0" style="width:55px;" class="badge badge-light-primary">
                  I-{{ data.item.invoice_id }}
                </span>
                <span v-else>-</span>
              </template>
              <template #cell(fraction_net_return)="data">
                <span v-if="data.item.fraction">
                  {{ data.item.fraction.net_return | formatAmount }}
                </span>
                <span v-else>-</span>
              </template>

              <template #cell(created_at)="data">
                <span v-if="data.item.created_at">{{
                  data.item.created_at | formatDate
                }}</span>
                <span v-else>CHF 00’000.00</span>
              </template>
              <template #cell(directions)="data">
                <feather-icon
                  v-if="data.item.type_description == 'Deposit' || data.item.type_description == 'invoice_sold' || data.item.type_description == 'invoice_paid' || data.item.type_description == 'Completed'"
                  icon="ArrowUpCircleIcon"
                  class="text-success"
                ></feather-icon>
                <feather-icon
                    v-else-if="$store.state.auth.user.crole == 'seller' && data.item.type_description == 'Purchase'"
                    icon="ArrowUpCircleIcon"
                    class="text-success"
                ></feather-icon>
                <feather-icon
                  v-else
                  icon="ArrowDownCircleIcon"
                  class="text-danger"
                ></feather-icon>
              </template>
              <template #cell(amount)="data">
                {{ data.item.amount | formatAmount }}
              </template>
              <template #cell(status)="data">
                <span class="text-secondary" v-if="data.value == 0"
                  >{{ $t("pending") }}
                </span>
                <span class="text-success" v-if="data.value == 1">{{
                  $t("approved")
                }}</span>
                <span class="text-danger" v-if="data.value == 2">{{
                  $t("declined")
                }}</span>
              </template>
              <template #cell(action)="data">
                <b-button
                  variant="outline-secondary"
                  @click="showModal(data.item)"
                  size="sm"
                >
                  <feather-icon icon="SearchIcon"></feather-icon>
                </b-button>
                <b-button
                  v-if="data.item.type_description == 'Purchase'"
                  class="ml-1 web"
                  variant="outline-success"
                  @click="readInvoice(data.item)"
                  size="sm"
                >
                  <FeatherIcon icon="BookOpenIcon"></FeatherIcon>
                </b-button>
                <b-button
                  v-if="data.item.type_description == 'Purchase'"
                  class="mt-1 mobile"
                  variant="outline-success"
                  @click="downloadPdf(data.item)"
                  size="sm"
                >
                  <FeatherIcon icon="DownloadIcon"></FeatherIcon>
                </b-button>
              </template>
            </Table>
          </b-col>
        </b-card>
    <b-modal
      size="lg"
      id="bv-modal-example"
      centered
      hide-footer
      :title="$t('details')"
    >
      <b-card-text class="text-left" v-if="singleItem">
        <h5>{{ $t("transaction_id") }} : {{ singleItem.id }}</h5>
        <h5>
          {{ $t("transaction_data") }} :
          {{ singleItem.created_at | formatDate }}
        </h5>
        <hr />
        <div class="row">
          <div class="col-md-6">
            <h5>{{ $t("transaction") }} :</h5>
            <div>
              {{ $t("status") }} :
              <span class="text-secondary" v-if="singleItem.status == 0"
                >{{ $t("pending") }}
              </span>
              <span class="text-success" v-if="singleItem.status == 1">{{
                $t("approved")
              }}</span>
              <span class="text-danger" v-if="singleItem.status == 2">{{
                $t("declined")
              }}</span>
            </div>
            <div>
              {{ $t("type") }} :
              <span class="text-primary">{{
                $t(singleItem.type_description)
              }}</span>
            </div>
            <div>
              {{ $t("direction") }} :
              <feather-icon
                v-if="singleItem.type != 0"
                icon="ArrowUpCircleIcon"
                class="text-success"
              ></feather-icon>
              <feather-icon
                v-else
                icon="ArrowDownCircleIcon"
                class="text-danger"
              ></feather-icon>
            </div>
            <div>
              {{ $t("currency") }} : <span class="text-primary">CHF</span>
            </div>
            <div v-if="seller != 0 && singleItem && singleItem.invoice">
               <!--{{ $t("invoice_amount") }} :-->
              <!--<span class="text-primary">-->
                <!--{{ singleItem.invoice.amount | formatAmountWithoutCurrency }}</span>-->
            </div>
            <div v-else>
              {{ $t("net_amount_sold") }}:
              <span class="text-primary">
                {{ singleItem.amount | formatAmountWithoutCurrency }}
              </span>
            </div>
          </div>
          <div v-if="singleItem.invoice!=null && (singleItem.invoice_id != null || singleItem.invoice_id !=0) " class="col-md-6">
            <h5>{{ $t("invoice_details") }} :</h5>
            <div>
              {{ $t("investment_amount") }} :
              <span class="text-primary" v-if="singleItem.fraction">
                {{ singleItem.fraction.amount | formatAmount }}
              </span>
              <span v-else>
                  12
                 {{ singleItem.amount | formatAmount }}
              </span>
            </div>
            <div v-if="seller != 0">
              {{ $t("expected_return") }} :
              <span class="text-primary">
                {{ singleItem.fraction.net_return | formatAmount }}
              </span>
            </div>
            <div v-if="seller != 0">
              {{ $t("roi_in_%") }} :
              <span class="text-primary">
                {{ calculateRoiPercentage(singleItem.invoice) }}
                <!--{{ Number( (singleItem.fraction.net_return * 100) / singleItem.fraction.amount ).toFixed(2)}} %-->
              </span>
            </div>
            <div>
              {{ $t("issue_date") }} :
              <span class="text-primary">
                {{ singleItem.invoice.created_at | formatDate }}</span
              >
            </div>
            <div>
              {{ $t("due_date") }} :
              <span class="text-primary">
                {{ singleItem.invoice.due_date | formatDate }}</span
              >
            </div>
            <div>
              {{ $t("payment_term") }} :
              <span class="text-primary">
                {{ singleItem.invoice.terms }}
              </span>
            </div>
            <div v-if="seller != 0">
              {{ $t("seller_industry") }} :
              <span
                class="text-primary"
                v-if="
                  singleItem.invoice &&
                  singleItem.invoice.company &&
                  singleItem.invoice.company.credit_reform &&
                  singleItem.invoice.company.credit_reform[0] &&
                  singleItem.invoice.company.credit_reform[0].industry
                "
              >
                {{ JSON.parse(singleItem.invoice.company.credit_reform[0].industry)[$store.state.app.language] }}
              </span>
            </div>
            <div v-if="seller != 0">
              {{ $t("debtor_industry") }} :
              <span
                class="text-primary"
                v-if="
                  singleItem.invoice.debtor &&
                  singleItem.invoice.debtor.credit_reform &&
                  singleItem.invoice.debtor.credit_reform[0] &&
                  singleItem.invoice.debtor.credit_reform[0].industry
                "
              >
                {{  JSON.parse(singleItem.invoice.debtor.credit_reform[0].industry)[$store.state.app.language] }}
              </span>
            </div>

            <br /><br />
          </div>
          <div
            v-if="singleItem.invoice_id != null && singleItem.fraction"
            class="col-md-6"
          >
            <h5>{{ $t("invoice_transaction") }} :</h5>
            <div>
              {{ $t("invoice_id") }} :
              <span class="text-primary">I-{{ singleItem.invoice.id }}</span>
            </div>
            <div v-if="seller != 0">
              {{ $t("fraction") }} :
              <span class="text-primary"
                >{{ singleItem.fraction.fraction }}%</span
              >
            </div>
            <div v-else>
              {{ $t("funding_status") }} :
              <span class="text-primary">100%</span>
            </div>
            <div>
              {{ $t("seller_id") }} :
              <span class="text-primary">C-{{
                singleItem.invoice.company.id
              }}</span>
            </div>
            <div>
              {{ $t("debtor_id") }} :
              <span class="text-primary">
                C-{{ singleItem.invoice.debtor.id }}</span
              >
            </div>
          </div>
          <div v-if="singleItem.invoice_id != null && singleItem.invoice != null" class="col-md-6">
            <div>
              {{ $t("market") }} :
              <span class="text-primary"> Switzerland </span>
            </div>
            <div>
              {{ $t("asset_class") }} :
              <span class="text-primary"> Invoice receivable </span>
            </div>
            <div>
              {{ $t("payment_term") }} :
              <!--<span class="text-primary"> Open; Non-Recourse </span>-->
              <span v-if="singleItem.invoice.recourse == 1" class="text-primary">Open; Recourse</span>
              <span v-else class="text-primary">Open; Non-Recourse</span>
            </div>
            <div>
              {{ $t("proof_of_ownership") }} :
              <span class="text-primary"> Received </span>
            </div>
            <div>
              {{ $t("sale") }} : <span class="text-primary">Normal</span>
            </div>
          </div>
        </div>
      </b-card-text>
      <hr />
    </b-modal>
</div>
</template>
<script>
import Table from "@/components/common/Table2.vue";
import ApproveModal from "@/components/common/ApproveModal";
import fileDownload from "js-file-download";
export default {
  components: { Table, ApproveModal },
  data() {
    return {
      payload: null,
      data: [],
      transactions: [],
      loader: false,
      fields: [
        {
          label: "date",
          align: "start",
          key: "created_at",
        },
        {
          label: "status",
          key: "status",
          customSlot: true,
          sortable: false,
        },
        {
          label: "type",
          align: "start",
          key: "type_description",
          sortable: false,
        },
        {
          label: "id",
          align: "start",
          key: "id",
          sortable: false,
        },
        {
          label: "directions",
          align: "center",
          key: "directions",
          sortable: false,
        },
        {
          label: "currency",
          align: "start",
          key: "currency",
          sortable: false,
        },
        {
          label: "amount",
          align: "right",
          key: "amount",
          sortable: false,
          tdClass: "text-right",
          thClass: "text-right",
        },
        {
          label: "invoice_id",
          align: "start",
          key: "invoice_id",
          sortable: false,
        },
        {
          label: "fraction",
          align: "start",
          key: "fraction",
          sortable: false,
        },
        {
          label: "action",
          align: "start",
          key: "action",
          sortable: false,
        },
      ],
      dialog: false,
      invoice: null,
      items: [],
      singleItem: null,
      type: "",
      base64: "",
      isLoading: false,
      totalItems: 0,
      currentPage: 1,
      // perPage: 12,
      invoices: [],
      balance: null,
      url: `/transactions`,
      currencies: null,
      tabIndex: 0,
      currency: {
        name: "CHF",
        id: 1,
      }
    };
  },
  created() {
    this.getBalance(1);
    this.getTransactions();
    this.getCurrencies();
    if (this.seller == 0) {
      // this.fields[8] = {
      //   label: "financing_status",
      //   align: "start",
      //   key: "financing_status",
      //   sortable: false,
      // };
    } else {
      this.fields[8] = {
        label: "fraction",
        align: "start",
        key: "fraction",
        sortable: false,
      };
    }
  },
  computed: {
    seller() {
      return this.$store.state.app.company.value.buyer;
    },
    rows() {
      return this.totalItems;
    },
  },
  methods: {
    numberFormat(number) {

      return number.toFixed(2);
    },
    changeTab(id, name, tabIndex) {
        this.currency.name = name;
        this.currency.id = id;
        this.tabIndex = tabIndex;
        this.getBalance(id);
    },
    getCurrencies() {
      this.$http.post("/currency").then((response) => {
        if (response.data.status == 200) {
          this.currencies = response.data.value;
        }
      });
    },

    getInvoices(){
      this.loader = true;
      this.$http.post("/market/invoices/status/" + 10).then((res) => {
          if (res) {
            this.invoices = res.data.value.data;
            this.loader = false;
          }
        });
    },
    InvoiceDetails(record, index) {
      this.$router.push("/market/" + record.id);
    },
    downloadPdf(item) {
      this.$http
        .post(
          "/invoice/fraction/download/" + item.fraction_id,
          {},
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          fileDownload(response.data, item.invoice.title);
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    readInvoice(item) {
      this.$http
        .post("/invoice/fraction/read/" + item.fraction_id, {
          responseType: "json",
        })
        .then((response) => {
          this.type = "";
          this.base64 = "";

          this.base64 = response.data.value.b64;
          this.type = response.data.value.ext;

          this.$store.commit("app/SET_FILE_TYPE", this.type);
          this.$store.commit("app/SET_FILE_BASE64", this.base64);

          window.open("/read-files", "_blank");
        })
        .catch((error) => {
          this.isLoading = false;
        });
    },
    showModal(item) {
      this.singleItem = item;
      this.$bvModal.show("bv-modal-example");
    },
    myRowClickHandler(record, index) {
      this.singleItem = record.fraction;
      if (this.singleItem) {
        this.dialog = true;
      }
    },
    getTransactions() {
      let data = { company_id: this.$store.state.auth.user.company_id };
      this.payload = data;

      this.$http.post(`/transactions?page=${this.currentPage}`, data).then((res) => {
        if (typeof res.data.value.data != "undefined" && res.data.value.data) {
          this.transactions = res.data.value.data;
          this.totalItems = res.data.value.total;
          this.currentPage = res.data.value.current_page;
        }
        this.loader = false;
      });
    },
    getBalance(id) {
      this.loader = true;
      this.$http.post("/transactions/getBalance", {'currency_id': id}).then((res) => {
        if (typeof res.data.value != "undefined" && res.data.value) {

          this.data = res.data.value;
          this.balance = res.data.value;

          console.log("id", id);
          console.log(11, this.data);
          console.log(22, this.balance);

        }
        this.loader = false;
      });
    },
  },
};
</script>
